import Autolinker from 'autolinker';

export function addAnchorTags(html, users) {
  if (html && html.length) {
    let temp = html.replace('&nbsp;', ' ');
    if (temp !== null) {
      let returned = '';
      // while the incoming string contains at least 1 '@'
      while (temp.includes('@')) {
        // find the index of the nxt '@'
        const index = temp.indexOf('@');
        // if it is not the first character
        if (index !== 0) {
          // enter all chars before '@'
          returned += temp.substring(0, index);
          // remove those chars from temp
          temp = temp.substring(index, temp.length);
          // get the userName of the tagged user
          let index2 = temp.indexOf('<');
          if (index2 === -1) {
            index2 = temp.length;
          }
          const userName = temp.substring(0, index2).replace(/ /g, '');
          // get that user's ID
          const userIndex = users.findIndex((user) =>
            userName.startsWith(`@${user.firstName.replace(/ /g, '')}${user.lastName.replace(/ /g, '')}`),
          );
          if (userIndex > -1) {
            const userId = users[userIndex].id;
            returned += `<a href="/user/${userId}" style="color: #00B0F0;">${userName}</a>`;
          } else {
            returned += `<span style="font-weight: 700;">${userName}</span>`;
          }
          temp = temp.substring(index2, temp.length);
        }
        // if '@' is the first character
        if (index === 0) {
          let index2 = temp.indexOf(' ');
          if (index2 === -1) {
            index2 = temp.length;
          }
          const userName = temp.substring(0, index2).replace(/ /g, '');
          // get that user's ID
          const userIndex = users.findIndex((user) =>
            userName.startsWith(`@${user.firstName.replace(/ /g, '')}${user.lastName.replace(/ /g, '')}`),
          );
          if (userIndex > -1) {
            const userId = users[userIndex].id;
            returned += `<a href="/user/${userId}" style="color: #00B0F0;">${userName}</a>`;
          } else {
            returned += `<span style="font-weight: 700;">${userName}</span>`;
          }
          temp = temp.substring(index2, temp.length);
        }
      }
      return Autolinker.link(`${returned} ${temp}`);
    }
  }
  return Autolinker.link(html);
}

export function stripHTML(string) {
  const strippedString = string.replace(/(<([^>]+)>)/gi, ' ');
  const furtherStrippedString = strippedString.replace(/\n/g, ' ');
  const txt = document.createElement('textarea');
  txt.innerHTML = furtherStrippedString;
  const { value } = txt;
  txt.remove();
  return value;
}

export function shorten(string) {
  if (string.length > 150) {
    return `${string.substring(0, 150)}...`;
  }
  return string;
}
